import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import {
  AiOutlineInstagram as Instagram,
  AiOutlineTwitter as Twitter,
} from 'react-icons/ai';
import {
  GrFacebookOption as Facebook,
  GrLinkedinOption as LinkedIn,
} from 'react-icons/gr';

import Container from '../Container';
import GoogleCloud from '../../assets/logos/google-cloud.png';
import SAP from '../../assets/logos/sap.inline.svg';
import FGT from '../../assets/logos/fulcrumgt_preferred.png';
import Ornament from '../../assets/footer-ornament.inline.svg';
import HubspotEmbed from '../HubspotEmbed';
//

const Footer = ({content}) => {

  return (
  <FooterWrap>
    <FooterContainer>
      <section>
        <div className="footer__ornament">
          <Ornament />
        </div>

        <div className="footer__cols">
          <div>
            <ul>
              <li className="title">Quick Links</li>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/solutions/fulcrum-snap">Fulcrum Snap Products</Link>
              </li>
              <li>
                <Link to="/solutions/deployment">Solution Deployment</Link>
              </li>
              <li>
                <Link to="https://knowledgebase.fulcrumgt.com">Knowledge Base</Link>
              </li>
              <li>
                <Link to="https://info.fulcrumgt.com/en-us/request-a-demo-with-a-product-expert">Book a Demo</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li className="title">Company</li>
              <li>
                <Link to="/our-company">About Us</Link>
              </li>
              <li>
                <Link to="/our-offices">Our Offices</Link>
              </li>
              <li>
                <Link to="/partnerships">Our Partnerships &amp; Alliances</Link>
              </li>
              <li>
                <Link to="/solutions/technology">Our Technology</Link>
              </li>
              <li>
                <Link to="/community">Community</Link>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section>
        <div className="footer__ornament">
          <Ornament />
        </div>

        <div className="footer__cols">
          <div>
            <ul>
              <li className="title">Contact Us</li>
              <li>
                <address>
                  Fulcrum Global Technologies
                  <br />
                  Headquarters
                  <br />
                  1 E Wacker Dr
                  <br />
                  Suite 2150
                  <br />
                  Chicago, IL 60601
                </address>
              </li>
              <li>
                <a href="mailto:info@fulcrumgt.com">info@fulcrumgt.com</a>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                <ul className="footer__matrix">
                  <li>
                    <a
                      href=" https://pf-prod-sapit-partner-prod.cfapps.eu10.hana.ondemand.com/#/partner/details/0001061456"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SAP />
                    </a>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={FGT} alt="FulcrumGT" width="100" />
                    </Link>
                  </li>
                  <li>
                    <a href="https://cloud.google.com/blog/products/gcp">
                      <img src={GoogleCloud} alt="Google Cloud Partner" />
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul className="footer__social">
                  <li>
                    <a
                      href="https://www.instagram.com/fulcrumgt/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Instagram />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/FulcrumGT"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Twitter />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/FulcrumGT/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Facebook />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/fulcrumgt/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedIn />
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  &copy; 2023 Fulcrum Global Technologies
                  Inc. All rights reserved.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
       <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/5325021.js"></script>
       
    </FooterContainer>
  </FooterWrap>
)

};

export default Footer;

const FooterContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const FooterWrap = styled.footer`
  padding: 4rem;
  background-color: var(--grey--2);

  .footer__ornament {
    margin-bottom: 1.6rem;

    @media (min-width: 1024px) {
      margin-bottom: 2.4rem;
    }

    svg {
      width: 100%;
    }
  }

  section {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 1.6rem);

      &:first-of-type {
        margin-right: 1.6rem;
      }

      &:last-of-type {
        margin-left: 1.6rem;
      }
    }
  }

  .footer__cols {
    display: flex;
    flex-direction: column;

    @media (min-width: 540px) {
      flex-direction: row;
    }

    > div {
      width: 100%;
      margin: 0 auto 1.6rem;

      @media (min-width: 540px) {
        width: calc(50% - 1.6rem);
        min-width: unset;
        margin: 0 1.6rem 1.6rem 0;

        &:last-of-type {
          width: 50%;
          margin-right: 0;
        }
      }

      @media (min-width: 768px) {
        margin: 0 1.6rem 0 0;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      color: var(--grey--0);
      font-size: 1.4rem;
      line-height: 2.4rem;

      @media (min-width: 768px) {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }

      @media (min-width: 1024px) {
        font-size: 1.4rem;
        line-height: 2.4rem;
      }

      address {
        font-style: normal;
      }

      a {
        color: var(--grey--0);

        &:hover {
          text-decoration: underline;
        }
      }

      &.title {
        margin-bottom: 0.8rem;
        font-weight: 700;
      }
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.6rem;
  }

  .footer__matrix,
  .footer__social {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.4rem;

    li {
      transition: 250ms ease opacity;
      opacity: 1;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  .footer__matrix {
    justify-content: space-between;

    @media (min-width: 768px) {
      justify-content: center;

      li {
        margin-bottom: 0.8rem;
      }
    }

    @media (min-width: 1280px) {
      justify-content: space-between;
    }
  }

  .footer__social {
    margin: 1.6rem 0 !important;

    li {
      width: 2rem;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;
