export default [
  {
    id: 0,
    title: 'Home',
    link: '/',
    children: null,
  },

  {
    id: 1,
    title: 'Solutions',
    link: null,
    children: [
      {
        id: 0,
        eyebrow: 'Overview',
        links: [
          {
            id: 0,
            title: 'Digital Business Platform',
            description: null,
            links: [
              {
                id: 0,
                title: 'Fulcrum Snap',
                link: 'solutions/fulcrum-snap',
                content: {
                  intro: 'The All-In-One Digital Business Platform',
                  tags: null,
                  image:
                    'https://a.storyblok.com/f/102444/4501x2918/2c6c9f6812/menu_images_snap.png',
                  outro:
                    'Quick-to-Deploy and Simple-to-Run, Comprehensive Business Operations',
                },
              },
              {
                id: 1,
                title: 'Integrations & Technology',
                link: 'solutions/technology',
                content: {
                  intro: 'Secure, Comprehensive, and Future-Ready',
                  tags: null,
                  image:
                    'https://a.storyblok.com/f/102444/4501x2918/17d22e130c/menu_images_integration-and-technology.png',
                  outro:
                    'An in-memory, single source of truth system consistently innovated on to meet the needs of the digital firm.',
                },
              },
            ],
          },
          {
            id: 1,
            title: 'RAPIDx',
            description: null,
            links: 'solutions/solutions-rapidx',
          },
        ],
      },
      {
        id: 1,
        eyebrow: 'Business Processes',
        links: [
          {
            id: 0,
            title: 'Matter Inception & Execution',
            description: null,
            links: [
              {
                id: 0,
                title: 'Epoch™ Time Management',
                link: 'solutions/epoch',
                content: {
                  intro:
                    'A time management solution that completely records and effortlessly tracks every minute that matters.',
                  tags: null,
                  image:
                    'https://a.storyblok.com/f/102444/4501x2918/5b7b0e46bd/menu_images_epoch.png',
                  outro:
                    'Effortless time recording, cleaner data, improved realization.',
                },
              },
              {
                id: 1,
                title: 'Upfront™ New Business Intake',
                link: 'solutions/upfront',
                content: {
                  intro:
                    'Efficient, flexible, accurate client and matter registration',
                  tags: null,
                  image:
                    'https://a.storyblok.com/f/102444/4501x2918/071a62ac50/menu_images_upfront.png',
                  outro:
                    'Comprehensive client and matter registration that delivers best-practices throughout the new business intake process',
                },
              },
              {
                id: 2,
                title: 'Curo™ Matter Management',
                link: 'solutions/curo',
                content: {
                  intro:
                    'Scope, price, and manage projects all in one solution',
                  tags: null,
                  image:
                    'https://a.storyblok.com/f/102444/4501x2918/c160bc5511/menu_images_curo.png',
                  outro:
                    'A matter management solution that enables in-depth control over diverse projects all in one place',
                },
              },
            ],
          },
          {
            id: 1,
            title: 'Business Operations',
            description: null,
            links: [
              {
                id: 0,
                title: 'Snap™ Billing & Finance',
                link: 'solutions/billing',
                content: {
                  intro:
                    'The most powerful and scalable in-memory billing solution',
                  tags: null,
                  image:
                    'https://a.storyblok.com/f/102444/4501x2918/145136a586/menu_images_snap-b-f.png',
                  outro:
                    'Fast, flexible, and future-ready – a solution that meets financial operations challenges and improves a firm’s business management',
                },
              },
              {
                id: 1,
                title: 'Salvo™ E-Billing',
                link: 'solutions/ebilling',
                content: {
                  intro:
                    'Modern, streamlined, and simplified ebilling operations',
                  tags: null,
                  image:
                    'https://a.storyblok.com/f/102444/4501x2918/5ee38dfc03/menu_images_salvo.png',
                  outro:
                    'Prepare invoices and track billing rules in real-time at comprehensive levels of details',
                },
              },
              {
                id: 2,
                title: 'Snap™ Revenue Management',
                link: 'solutions/revenue-management',
                content: {
                  intro:
                    'Improve cashflows, manage write-offs, and reduce bad debt',
                  tags: null,
                  image:
                    'https://a.storyblok.com/f/102444/4501x2918/72ccfb70c5/menu_images_snap-rm.png',
                  outro:
                    'Automate and collaborate on processing, posting, and confirming invoice collections',
                },
              },
              {
                id: 3,
                title: 'Snap™ HCM',
                link: 'solutions/hcm',
                content: {
                  intro: 'An Integrated Productivity Enhancement Suite',
                  tags: null,
                  image:
                    'https://a.storyblok.com/f/102444/4319x2514/41dba78269/menu_images_snap-hcm.png',
                  outro:
                    'Comprehensive human capital management designed specifically with your firm’s employees in mind',
                },
              },
              {
                id: 4,
                title: 'Snap™ Budgets',
                link: 'solutions/snap-budgets',
                content: {
                  intro:
                    'In-depth insight and control over budgets in a smart, real-time, consolidated system.',
                  tags: null,
                  image:
                    'https://a.storyblok.com/f/102444/4501x2918/d07699cf18/menu_images_zooboss.png',
                  outro:
                    'Maximize budget accuracy and effectiveness, reduce administrative burden, and enable your firm to become more efficient in budgeting cycles',
                },
              },
            ],
          },
          {
            id: 5,
            title: 'Legal Business Network',
            description: null,
            links: 'solutions/solutions-rapidx',
          },
          {
            id: 2,
            title: 'Performance Analytics',
            description: null,
            links: [
              {
                id: 0,
                title: 'Snap™ Insight',
                link: 'solutions/insight',
                content: {
                  intro: 'Robust analytics for the digital firm',
                  tags: null,
                  image:
                    'https://a.storyblok.com/f/102444/4501x2918/514e77d359/menu_images_snap-insight.png',
                  outro:
                    'Powerful analytics that provides firms with immediate access to relevant data through role-based dashboards and reports',
                },
              },
              {
                id: 1,
                title: 'ZooBoss™ Data Management',
                link: 'solutions/zooboss',
                content: {
                  intro: 'Tame your data',
                  tags: null,
                  image:
                    'https://a.storyblok.com/f/102444/4501x2918/d07699cf18/menu_images_zooboss.png',
                  outro:
                    'Spend less time connecting data and more time applying data-driven insights to advance your business.',
                },
              },
            ],
          },
          {
            id: 3,
            title: 'Operations-as-a-Service',
            description: null,
            links: [
              {
                id: 0,
                title: 'Virtual Operations Deployment',
                link: 'solutions/vod',
                content: {
                  intro: 'Enter Time, Get Paid, We Do Everything in Between',
                  tags: null,
                  image:
                    'https://a.storyblok.com/f/102444/4501x2918/22e850a798/menu_images_vod.png',
                  outro:
                    'Fulcrum solutions and VOD team deliver high quality, cost-effective, repeatable and reliable processes for your firm',
                },
              },
              {
                id: 1,
                title: 'Fulcrum Managed Care',
                link: 'solutions/managed-care',
                content: {
                  intro: 'Offering in-depth, business critical support',
                  tags: null,
                  image:
                    'https://a.storyblok.com/f/102444/4501x2918/935c809e3f/menu_images_managed-care.png',
                  outro:
                    'Helping organizations achieve strategic, technical, and operational objectives by supplementing local expertise across technical and business support, technical development and operational management.',
                },
              },
            ],
          },
          {
            id: 4,
            title: 'Integration Hub',
            description: null,
            links: [
              {
                id: 0,
                title: 'BI Connect',
                link: 'solutions/bi-connect',
                content: {
                  intro:
                    'Designed for seamless interoperability with third-party business intelligence systems',
                  tags: null,
                  image:
                    'https://img2.storyblok.com/1620x911/smart/filters:quality(100):format(webp)/f/102444/8000x4501/53e29b2c82/header-connect-bi-622-v1.png',
                  outro:
                    'Unleash full business intelligence capabilities with BI Connect™',
                },
              },
              {
                id: 1,
                title: 'HCM Connect',
                link: 'solutions/hcm-connect',
                content: {
                  intro:
                    'Designed for seamless interoperability with third-party HCM systems',
                  tags: null,
                  image:
                    'https://img2.storyblok.com/1620x911/smart/filters:quality(100):format(webp)/f/102444/8000x4501/f451c8829b/header-connect-hcm-622-v1.png',
                  outro:
                    'A HCM integration solution that allows fast and secure key people data exchange',
                },
              },
              {
                id: 2,
                title: 'Time Connect',
                link: 'solutions/timeconnect',
                content: {
                  intro:
                    'Designed for seamless interoperability with third-party time management systems',
                  tags: null,
                  image:
                    'https://img2.storyblok.com/1620x911/smart/filters:quality(100):format(webp)/f/102444/8000x4501/7bd43e9510/header-connect-time-622-v1.png',
                  outro:
                    'A time management integration solution that eliminates data silos and simplifies digital access.',
                },
              },
            ],
          },
        ],
      },
      {
        id: 2,
        eyebrow: 'Services',
        links: [
          {
            id: 0,
            title: 'Solution Deployment',
            description: null,
            links: [
              {
                id: 0,
                title: 'Deployment',
                link: 'solutions/deployment',
                content: {
                  intro:
                    "Multiple, time-tested deployment methods to fit your firm's needs",
                  tags: null,
                  image:
                    'https://a.storyblok.com/f/102444/4501x2918/7b0d145b66/menu_images_deployment.png',
                  outro: null,
                },
              },
              {
                id: 1,
                title: 'Data Services',
                link: 'solutions/data-services',
                content: {
                  intro:
                    "Multiple, time-tested deployment methods to fit your firm's needs",
                  tags: null,
                  image:
                    'https://a.storyblok.com/f/102444/4501x2918/7b0d145b66/menu_images_deployment.png',
                  outro: null,
                },
              },
            ],
          },
          {
            id: 1,
            title: 'Advisory Services',
            description: null,
            links: [
              {
                id: 0,
                title: 'Risk Assessment',
                link: 'solutions/risk-assessment',
                content: {
                  intro:
                    'Leveraging Industry Expertise for Value-Driving Outcomes',
                  tags: null,
                  image:
                    'https://a.storyblok.com/f/102444/4501x2918/1ec527f820/menu_images_advisory-services.png',
                  outro:
                    'Services to make your business more efficient, your operations more profitable, and your peace of mind complete',
                },
              },
              {
                id: 1,
                title: 'E-Billing Efficacy',
                link: 'solutions/ebilling-efficacy',
                content: {
                  intro: null,
                  tags: null,
                  image:
                    'https://a.storyblok.com/f/102444/4501x2918/1ec527f820/menu_images_advisory-services.png',
                  outro:
                    'Services to make your business more efficient, your operations more profitable, and your peace of mind complete',
                },
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: 2,
    title: 'About',
    link: null,
    children: [
      {
        id: 0,
        eyebrow: null,
        links: [
          {
            id: 0,
            title: 'Our Company',
            description: null,
            links: 'our-company',
          },
          {
            id: 1,
            title: 'Industries',
            description: null,
            links: 'industries',
          },
          {
            id: 2,
            title: 'Our Offices',
            description: null,
            links: 'our-offices',
          },
          //{
          //  id: 3,
          //  title: 'Careers',
          //  description: null,
          //  links: 'careers',
          //},
          {
            id: 4,
            title: 'Partnerships',
            description: null,
            links: 'partnerships',
          },
          {
            id: 5,
            title: 'Community',
            description: null,
            links: 'community',
          },
        ],
      },
    ],
  },

  {
    id: 3,
    title: 'Knowledge Base',
    link: 'https://knowledgebase.fulcrumgt.com',
    children: null,
  },
  // {
  //   id: 3,
  //   title: 'Insights',
  //   link: '/insights',
  //   children: [
  //     {
  //       id: 0,
  //       eyebrow: null,
  //       links: [
  //         {
  //           id: 0,
  //           title: 'Announcements',
  //           description: null,
  //           links: 'announcements',
  //         },
  //         {
  //           id: 1,
  //           title: 'Resources',
  //           description: null,
  //           links: 'resources',
  //         },
  //       ],
  //     },
  //   ],
  // },
];
