import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Squash as Hamburger } from 'hamburger-react';

import Logo from '../../assets/logos/fulcrumgt_preferred.png';
import Button from '../Button';
import Menu from './Menu';
import TrackingCodeHandler from '../TrackingCodeHandler';

//

const Header = ({ HeaderRef }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuL1, setMenuL1] = useState(null);
  const [menuL2, setMenuL2] = useState(null);
  const [menuL3, setMenuL3] = useState(null);

  const closeMenu = () => {
    setMenuOpen(false);
    setMenuL1(null);
    setMenuL2(null);
    setMenuL3(null);
  };

  useEffect(() => {
    if (menuOpen || menuL1 || menuL2 || menuL3) {
      return;
    }

    const allPanels = document.querySelectorAll('[data-panel-name]');
    allPanels.forEach((element) => {
      element.classList.add('hidden');
    });

    const allActive = document.querySelectorAll('header nav .active');
    allActive.forEach((element) => {
      element.classList.remove('active');
    });
  }, [menuL1, menuL2, menuL3, menuOpen]);

  useEffect(() => {
    const onResize = () => {
      // Mobile mode + mobile menu not open + has existing menu
      if (window.innerWidth < 1024 && !menuOpen && menuL1) {
        setMenuOpen(true);
      } else if (window.innerWidth >= 1024 && menuOpen) {
        setMenuOpen(false);
      }
    };

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [menuL1, menuOpen]);

  return (
    <>
      <TrackingCodeHandler />
      <Helmet>
        <style type="text/css">{`
          body { overflow: ${menuOpen ? 'hidden' : 'unset'}; }
          #hubspot-messages-iframe-container {
            transition: 150ms ease opacity;
            opacity: ${menuOpen ? '0' : '1'};
            pointer-events: ${menuOpen ? 'none' : 'unset'};
          }
        `}</style>
      </Helmet>

      <MainHeader ref={HeaderRef}>
        <HeaderWrap menuOpen={menuOpen}>
          <div className="header__logo">
            <Link to="/">
              <img src={Logo} alt="Fulcrum GT" width="150" />
            </Link>
          </div>

          <div className="burger">
            <Hamburger
              toggled={menuOpen}
              toggle={() => setMenuOpen(!menuOpen)}
              hideOutline
              size={20}
              color="var(--blue--1)"
            />
          </div>

          <div className="header__buttons">
            {!menuOpen && (
              <>
                <a href="https://snappps.fulcrumgt.com/">Login</a>

                <Button
                  content={{ label: 'Contact', to: 'contact' }}
                  customTheme={{
                    highlight: 'blue--0',
                    text: 'blue--0',
                    background: 'white',
                  }}
                  outline
                />
              </>
            )}
          </div>
        </HeaderWrap>

        <Menu
          menuOpen={menuOpen}
          menuL1={menuL1}
          setMenuL1={setMenuL1}
          menuL2={menuL2}
          setMenuL2={setMenuL2}
          menuL3={menuL3}
          setMenuL3={setMenuL3}
          closeMenu={closeMenu}
        />
        <Skrim type="button" menuOpen={menuOpen} onClick={() => closeMenu()} />
      </MainHeader>
    </>
  );
};

export default Header;

const Skrim = styled.button`
  display: none;

  @media (min-width: 1024px) {
    display: unset;
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    transition: var(--nav-transition);
    border: 0;
    opacity: ${({ menuOpen }) => (menuOpen ? '0.6' : '0')};
    background-color: var(--blue--1);
    cursor: pointer;
    pointer-events: ${({ menuOpen }) => (menuOpen ? 'unset' : 'none')};
  }
`;

const MainHeader = styled.header`
  position: absolute;
  z-index: 500;
  width: 100%;
  overflow: hidden;

  .burger {
    display: block;
    cursor: pointer;

    @media (min-width: 1024px) {
      display: none;
    }
  }
`;

const HeaderWrap = styled.section`
  display: flex;
  position: relative;
  z-index: 500;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - (var(--menuGutter) * 2));
  height: calc(var(--menuHeight) * 2);
  padding: var(--menuGutter);
  transition: 1s cubic-bezier(0.19, 1, 0.22, 1) background-color;

  @media (min-width: 768px) {
    width: calc(100% - (var(--menuGutter) * 3));
    padding: var(--menuGutter) calc(var(--menuGutter) * 1.5);
  }

  @media (min-width: 1024px) {
    pointer-events: ${({ menuOpen }) => (menuOpen ? 'none' : 'unset')};
  }

  .header__buttons {
    display: none;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 1.2rem;
      color: var(--blue--1);
      font-size: 1.2rem;
      line-height: 1.8rem;

      &:hover {
        color: var(--blue--2);
      }
    }

    @media (min-width: 1024px) {
      display: flex;
    }

    a:first-of-type {
      margin-right: 1.6rem;
    }
  }
`;

Header.propTypes = {
  HeaderRef: PropTypes.object.isRequired,
};
